import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { withPrefix } from 'gatsby'
import { graphql, StaticQuery, Link } from 'gatsby'
import ArticleCard from './BlogArticleCard'
import SectionTitle from '../general/SectionTitle'
import ReadMoreLink from '../general/ReadMoreLink'
import _ from 'lodash'
/**
 * ToDo: this is crude way to limit the number of post shown for one author to a max of two
 * Find a better way
 * @Khaled you can google what "crude" means
 */
/* const sanitizeAllPosts = (allPosts) => {
  try {
    const authors = {}
    const posts = allPosts.filter((post) => {
      const { node } = post
      const { author = {} } = node
      const { id: authorId } = author
      authors[authorId] = authors[authorId] >= 0 ? ++authors[authorId] : 0

      if (authors[authorId] > 2) return false
      else return true
    })
    return posts
  } catch (err) {
    return allPosts
  }
}

const priortiseTagsWithMorePhotos = (tags) => {
  return _.orderBy(
    tags,
    (tag) => tag.postsWithImages.length + tag.postsWithoutImages.length,
    ['desc']
  )
}

const sortArticlesByImages = (allTags) => {
  const MAX_POSTS_WITH_IMAGES = 2
  const MAX_POSTS_WITHOUT_IMAGES = 5

  const processedTags = []

  allTags.forEach((tag) => {
    let postsWithImages = [],
      postsWithoutImages = []

    const {
      node: { articles = [] },
    } = tag
    const publishedArticles = articles.filter(
      (elem) => elem.status === 'Published'
    )
    publishedArticles.forEach((article) => {
      if (
        article.image &&
        article.image.url &&
        postsWithImages.length < MAX_POSTS_WITH_IMAGES
      ) {
        postsWithImages.push(article)
      } else {
        if (postsWithoutImages.length < MAX_POSTS_WITHOUT_IMAGES) {
          postsWithoutImages.push(article)
        }
      }
    })

    const MAX_POSTS = 6
    let readmore = false
    const totalPosts = postsWithImages.length + postsWithoutImages.length

    if (totalPosts > MAX_POSTS) {
      postsWithoutImages = postsWithoutImages.slice(
        0,
        MAX_POSTS - postsWithImages.length
      )
      readmore = true
    }
    if (totalPosts > 0) {
      processedTags.push({
        tagName: tag.node.tagName,
        tagId: tag.node.id,
        postsWithImages,
        postsWithoutImages,
        readmore,
      })
    }
  })
  return processedTags
}
 */
const MAX_POSTS = 6

const priortiseTagsWithMoreArticles = (tags) => {
  return _.orderBy(tags, (tag) => tag.node.articles.length, ['desc'])
}

const priortiseNewestArticles = (tags) => {
  return tags.map((tag) => {
    return {
      tagName: tag.node.tagName,
      articles: _.orderBy(tag.node.articles, ['publishDate'], ['desc']).slice(
        0,
        MAX_POSTS
      ),
    }
  })
}

const BlogRollTemplate = (props) => {
  const { data = {} } = props
  const { allStrapiTags: { edges: allTags = [] } = {} } = data

  let tags = priortiseTagsWithMoreArticles(allTags)
  tags = priortiseNewestArticles(tags)

  return (
    <>
      <Helmet titleTemplate="%s | مركز الدراسات السودانية">
        <title>{`مقالات ونصوص`}</title>
        <meta property="og:title" content={'مقالات ونصوص'} />
        <meta
          name="description"
          content={
            'تحتوي هذه الصفحة على مقالات ونصوص لأصدقاء وأعضاء المركز. راسلونا على editor@ssc-sudan.org لإضافة مواضيعكم على هذه الصفحة.'
          }
        />
        <meta property="og:image" content={`${withPrefix('/')}img/logo.jpg`} />
      </Helmet>

      {tags.map((tag) => {
        return (
          <div className="section pb-0" key={tag.tagId}>
            <Link to={`/tags/${tag.tagName}`}>
              <SectionTitle>{tag.tagName}</SectionTitle>
            </Link>
            <div className="columns is-multiline">
              {tag.articles.map((post) => (
                <ArticleCard
                  key={post.id}
                  excerptLength={200}
                  author={post.author}
                  post={post}
                  showFeaturedImage
                />
              ))}
            </div>
            {tag.readmore && (
              <div>
                <ReadMoreLink link={`/tags/${tag.tagName}`}>
                  اقرأ بقية المواضيع في {tag.tagName}
                </ReadMoreLink>
              </div>
            )}
          </div>
        )
      })}
    </>
  )
}

const BlogRoll = () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allStrapiTags(sort: { order: DESC, fields: articles___created_at }) {
          edges {
            node {
              articles {
                author {
                  name
                  screenName
                }
                body
                created_at
                excerpt
                publishDate
                slug
                status
                title
                updated_at
                id
                image {
                  url
                }
              }
              tagName
              id
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRollTemplate data={data} count={count} />}
  />
)

BlogRollTemplate.propTypes = {
  data: PropTypes.shape({
    allStrapiTags: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default BlogRoll
