import React from 'react'
import Layout from '../../components/Layout'
import BlogRoll from '../../components/articles/BlogArticleRoll'
import PageHeader from '../../components/general/PageHeader'

const BlogIndexPage = () => {
  return (
    <Layout>
      <PageHeader
        className="hassan_3"
        backgroundImage="/img/backgrounds/hassanbg_3.jpeg"
      >
        مقالات ونصوص
      </PageHeader>

      <div className="container">
        <p className="has-text-centered is-small">
          تحتوي هذه الصفحة على مقالات ونصوص لأصدقاء وأعضاء المركز. راسلونا على{' '}
          <a href="editor@ssc-sudan.org">editor@ssc-sudan.org</a> لإضافة
          مواضيعكم على هذه الصفحة.
        </p>
      </div>
      <div className="container">
        <BlogRoll />
      </div>
    </Layout>
  )
}

export default BlogIndexPage
